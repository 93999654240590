<template>
  <div class="divBox">
    <el-card>
      <FormEditor
        :details="details"
        @editoChange="editoChange"
        :disabled="false"
      />
      <el-button  type="primary" :loading="vLoading" style="margin-top: 20px;" @click="save">提交</el-button>
    </el-card>
  </div>
</template>
<script>
import {
  descView,
  descUpdate
} from "@/api/balance/balance";
import FormEditor from "@/components/formEditor/index";
export default {
  name: "banner",
  components: {
    FormEditor,
  },
  data() {
    return {
      vLoading:false,
      details: "",
    };
  },
  mounted() {
    this.getView()
  },
  methods: {
    editoChange(e) {
      this.details = e;
    },
    getView(){
      descView({})
        .then((res) => {
          this.details = res.data.content
        })
        .catch((res) => {
        });
    },
    save(){
      this.vLoading = true
      descUpdate({content:this.details})
        .then((res) => {
         if(res.code == 200){
           this.$message({
             message: '修改成功',
             type: 'success'
           });
           this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.vLoading = false;
         }

        })
        .catch((res) => {
          this.vLoading = false;
        });
    }
  },
};
</script>
  
<style lang="scss" scoped>
</style>
  